






import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter, { Route, NavigationGuardNext } from 'vue-router';
import AccountForm from '../../plugins/AccountForm';
import { GetAccountResponse, ErrorResponse } from '../../../types/typescript-axios/api';
import axios, {AxiosResponse, AxiosError} from 'axios';
import Error from '../../components/contents/Error.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);
@Component({
  mixins: [
    AccountForm,
  ],
  components: {
    Error,
  },
})
export default class EditAccountParent extends Vue {
  private status: number = 200;
  private errorCode: number|null = null;
  private beforeMail: string = '';
  private stepNavi: StepNaviData[] = [
    {
      title: '情報変更',
    },
    {
      title: '確認',
    },
    {
      title: '変更完了',
    },
  ];

  @Emit()
  private setStatus(value: number): void {
    this.status = value;
  }

  @Emit()
  private resetStatusCode(): void {
    this.status = 200;
    this.errorCode = null;
  }

  @Emit()
  private setErrorCode(value: number): void {
    this.errorCode = value;
  }

  private async beforeRouteEnter(to: VueRouter, from: VueRouter, next: any) {
    await axios.get('/wp-json/moc/account')
    .then((response: AxiosResponse<GetAccountResponse>) => {
      next((vm: {
        status: number,
        agree: boolean,
        mail: string,
        name1: string,
        name2: string,
        birthYear: string,
        companyName: string,
        departmentName: string,
        sex: number ,
        consent: boolean ,
        password: string,
        newPassword: string,
        mailReInput: string,
        passwordReInput: string,
        newPasswordReInput: string,
        secretQuestAans: string,
        tel1: string,
        tel2: string,
        tel3: string,
        beforeMail: string,
      }) => {
        vm.status = response.status;
        vm.name1 = response.data.sei;
        vm.name2 = response.data.mei;
        vm.mail = response.data.email;
        vm.beforeMail = response.data.email;
        vm.sex = response.data.sex;
        vm.consent = response.data.exist_pic;
        vm.companyName = response.data.comp_nm;
        vm.departmentName = response.data.div_nm;
        vm.birthYear = response.data.birth_year;
        vm.tel1 = response.data.tel1;
        vm.tel2 = response.data.tel2;
        vm.tel3 = response.data.tel3;
      });
    })
    .catch( (e: AxiosError<ErrorResponse>) => {
      let status = 500;
      if (typeof e.response !== 'undefined') {
        status = e.response.status;
      }
      next((vm: {status: number}) => {
        vm.status = status;
      });
    });
  }
}
